<template>
    <div 
        class="field-wrap"
        :class="{highlighted:highlighted}"
    >

        <!--<div class="field-col-wrap">
            <span>{{field.label[this.$store.state.user.languageCode].value}}</span>
            <div class="sepperator"></div>
            <label>{{field.label[this.$store.state.user.languageCode].value}}</label>
        </div>-->
        <div class="sepperator">
            <div class="fieldSymbols">
                <img v-if='locked === "unlocked"' class='qrSymbol' :src='getImgUrl("qr_code.svg")'/>
                <imageViewer v-if='uploadedImages.length > 0' :files='this.uploadedImages'></imageViewer>
                <!--<img v-if='hasImage === true' class='qrSymbol' :src='getImgUrl("image.svg")'/>-->
            </div>
        </div>
        
         <transition name="highlight-unlocked" mode="out-in">
            <fieldLock 
                v-if='locked !== "unlocked" && locked !== false' 
                :lockType='locked'
                :formId='formId'
                :taskId='taskId'
                :fieldId='field.form_field_id'
                :parentId='field.parent_id'
                :label='getValueForCurLanguage(field.field_label).value'
                :description='getValueForCurLanguage(field.field_description).value'
                :isPersonal='isPersonal'>
            </fieldLock>
            <div v-else-if="field.field_type.type === 'text'">
                <textField 
                    :formId='formId.toString()'
                    :taskId='taskId'
                    :fieldId='field.form_field_id.toString()'
                    :parentId='field.parent_id'
                    :placeholder='getValueForCurLanguage(field.field_placeholder).value'
                    :value='getValue'
                    :label='getValueForCurLanguage(field.field_label).value'
                    :required='getRequired'
                    :oneLine='true'
                    fix:false
                    :isPersonal='isPersonal'>
                </textField>
            </div>
            <div v-else-if="field.field_type.type === 'textarea'">
                <textField 
                    :formId='formId.toString()'
                    :taskId='taskId'
                    :fieldId='field.form_field_id.toString()'
                    :parentId='field.parent_id'
                    :placeholder='getValueForCurLanguage(field.field_placeholder).value'
                    :value='getValue'
                    :label='getValueForCurLanguage(field.field_label).value'
                    :required='getRequired'
                    fix:false
                    :isPersonal='isPersonal'>
                </textField>
            </div>
            <div v-else-if="field.field_type.type === 'number'">
                <numberField 
                    :formId='formId.toString()'
                    :taskId='taskId'
                    :fieldId='field.form_field_id.toString()'
                    :parentId='field.parent_id'
                    :placeholder='getValueForCurLanguage(field.field_placeholder).value'
                    :value='getValue'
                    :label='getValueForCurLanguage(field.field_label).value'
                    :required='getRequired'
                    :min='field.min'
                    :max='field.max'
                    :step='field.step'
                    fix:false
                    :isPersonal='isPersonal'>
                </numberField>
            </div>
            <div v-else-if="field.field_type.type === 'boolean'">
                <boolean 
                    :formId='formId'
                    :taskId='taskId'
                    :fieldId='field.form_field_id'
                    :parentId='field.parent_id'
                    :value='field.value'
                    :label='getValueForCurLanguage(field.field_label).value'
                    :description='getValueForCurLanguage(field.field_description).value'
                    :required='getRequired'
                    :isPersonal='isPersonal'>
                </boolean>
            </div>
            <div v-else-if="field.field_type.type === 'radio'">
                <radio 
                    :formId='formId'
                    :taskId='taskId'
                    :fieldId='field.form_field_id'
                    :parentId='field.parent_id'
                    :value='field.value'
                    :label='getValueForCurLanguage(field.field_label).value'
                    :description='getValueForCurLanguage(field.field_description).value'
                    :required='getRequired'
                    :isPersonal='isPersonal'>
                </radio>
            </div>
            <div v-else-if="field.field_type.type === 'checkbox'">
                <check 
                    :formId='formId'
                    :taskId='taskId'
                    :fieldId='field.form_field_id'
                    :parentId='field.parent_id'
                    :value='field.value'
                    :label='getValueForCurLanguage(field.field_label).value'
                    :description='getValueForCurLanguage(field.field_description).value'
                    :required='getRequired'
                    :isPersonal='isPersonal'>
                </check>
            </div>
            <div v-else-if="field.field_type.type === 'file'">
                <fileField 
                    :formId='formId'
                    :taskId='taskId'
                    :fieldId='field.form_field_id'
                    :parentId='field.parent_id'
                    :value='field.value'
                    :label='getValueForCurLanguage(field.field_label).value'
                    :description='getValueForCurLanguage(field.field_description).value'
                    :required='getRequired'
                    :isPersonal='isPersonal'>
                </fileField>
            </div>
            <div v-else-if="field.field_type.type === 'select'">
                <selectField 
                    :formId='formId'
                    :taskId='taskId'
                    :fieldId='field.form_field_id'
                    :parentId='field.parent_id'
                    :value='field.value'
                    :label='getValueForCurLanguage(field.field_label).value'
                    :description='getValueForCurLanguage(field.field_description).value'
                    :required='getRequired'
                    :isPersonal='isPersonal'>
                </selectField>
            </div>
            <div v-else-if="field.field_type.type === 'signature'">
                <signature 
                    :formId='formId'
                    :taskId='taskId'
                    :fieldId='field.form_field_id'
                    :parentId='field.parent_id'
                    :value='field.value'
                    :label='getValueForCurLanguage(field.field_label).value'
                    :description='getValueForCurLanguage(field.field_description).value'
                    :required='getRequired'
                    :isPersonal='isPersonal'>
                </signature>
            </div>
            <div v-else-if="field.field_type.type === 'hidden'">
            </div>
            <div v-else>
                {{this.$t("messages.unknownFieldType")}}:
                {{field.field_type.type}}
            </div>
        </transition>
    </div>
</template>

<script>
import BooleanWithLableDesc from "@/components/FormFields/BooleanWithLableDesc.vue";
import RadioWithLableDesc from "@/components/FormFields/RadioWithLableDesc.vue";
import CheckWithLableDesc from "@/components/FormFields/CheckWithLableDesc.vue";
import TextFieldWithLable from "@/components/FormFields/TextAreaWithLable.vue";
import NumberFieldWithLable from "@/components/FormFields/NumberWithLable.vue";
import FileWithLableDesc from "@/components/FormFields/FileWithLableDesc.vue";
import SignatureField from "@/components/FormFields/SignatureField.vue";
import Select from "@/components/FormFields/Select.vue";
import FieldLock from "@/components/FormFields/FieldLock.vue";
import ImageViewer from "@/components/ImageViewer.vue";
import { mapGetters } from 'vuex'

export default {
    props:{
        field: Object,
        fix: Boolean,
        formId: Number,
        taskId: Number,
        highlighted: Boolean,
        isPersonal: Boolean
    },
    components: {
        'boolean':BooleanWithLableDesc,
        'check':CheckWithLableDesc,
        'radio':RadioWithLableDesc,
        'textField':TextFieldWithLable,
        'numberField':NumberFieldWithLable,
        'fieldLock':FieldLock,
        'fileField':FileWithLableDesc,
        'selectField':Select,
        'signature':SignatureField,
        'imageViewer':ImageViewer
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
    },
    computed:{
        uploadedImages: function(){
            let images = [];
            if(this.getCheckResultByFormId(this.formId,this.taskId,this.isPersonal) !== undefined){
                if(this.getCheckResultByFormId(this.formId,this.taskId,this.isPersonal).fields[this.field.form_field_id] !== undefined){
                    var optionsArray =this.getCheckResultByFormId(this.formId,this.taskId,this.isPersonal).fields[this.field.form_field_id].options
                    //var fileObjects =this.getCheckResultByFormId(this.formId,this.taskId,this.isPersonal).fields[this.field.form_field_id].fileObjects
                    if(optionsArray !== null && optionsArray !== undefined && optionsArray.length > 0){
                        optionsArray.forEach(function(option){
                            if(Array.isArray(option.fileObjects)){
                                images = option.fileObjects;
                            }
                        })
                    }/*else if(Array.isArray(fileObjects) && fileObjects.length > 0){
                        imagePresent = true;
                    }*/
                }
            }
            return images;
        },
        //why all this methods?!?!?! just get the values from field
        getValue: function() {
            if(this.getValueForCurLanguage(this.field.field_value).value){
                return this.getValueForCurLanguage(this.field.field_value).value;
            }else{
                return this.field.value
            }
        },
        getLabel: function() {
            return this.getValueForCurLanguage(this.field.field_label).value;
        },
        getPlaceholder: function() {
            return this.getValueForCurLanguage(this.field.field_placeholder).value;
        },
        getDescription: function() {
            return this.getValueForCurLanguage(this.field.field_description).value;
        },
        getRequired: function() {
            if(this.field.required){
                return this.field.required
            }else{
                return false
            }
        },
        locked: function(){
            if( this.form.fields[this.field.form_field_id] !== undefined 
                && this.form.fields[this.field.form_field_id].lockedBy !== undefined 
                && this.form.fields[this.field.form_field_id].lockedBy !== null){
                return this.form.fields[this.field.form_field_id].lockedBy;
            }else{
                return false
            }
        },
        form: function(){
            return this.getCheckResultByFormId(this.formId, this.taskId, this.isPersonal);
        },
        ...mapGetters([
            'getValueForCurLanguage',
            'getCheckResultByFormId'
        ])
    },
    watch: { 
        /*locked: function(newVal) { // watch it
            if(newVal == "unlocked"){
                
            }
        }*/
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "@/assets/scss/styles.scss";
    .field-wrap{
        //position: relative;
        color:$fontColorMedLight;
    }
    .highlighted{
        animation: bounce-in .4s;
    }
    .level1{
        margin: 5px 0;
    }
    .level2{
        margin:5px;
        .fieldSymbols{
            //position: absolute;
            float:left;
            top:8px;
            left:8px;
            height: 15px;
            z-index: 10;
            .qrSymbol{
                width: 20px;
                height: 20px;
            }
        }
        .sepperator{
            border-bottom:1px solid rgba(0,0,0,.1);
            width: 100%;
            margin: auto auto 24px auto;
        }
    }
    .highlight-unlocked-enter-active{
        animation: bounce-in .4s;
    }

    .highlight-unlocked-leave{
        opacity:1;
        display:none;
    }
    .highlight-unlocked-leave-active{
        display:block;
        transition: opacity .3s linear;
    }
    .highlight-unlocked-leave-to{
        opacity:0;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
</style>