<template>
    <div v-if='field.field_type.type !== "hidden"' class="field-wrap">
        <div class="sepperator"></div>
        <div class="result-wrap">
            {{getLabel}}:
            <div class="result-images" v-if='field.field_type.type === "boolean"'>
                <img class="result" v-if='getValue.severity === 0' :src="getImgUrl('Nav_check.svg')"/>
                <span class="result" v-else-if='getValue.severity === 1'>-</span>
                <img class="result" v-else-if='getValue.severity === 2' :src="getImgUrl('Nav_decline.svg')"/>
            </div>
        </div>
        
        <div v-if='hasSymbols === true' class="fieldSymbols">
            <imageViewer v-if='this.field.field_uploads != undefined' :files='this.field.field_uploads'></imageViewer>
            <div class="comment" v-for='(comment,index) of comments' :key='index'>
                <img  class='qrSymbol' :src='getImgUrl("menu-icons/Nachrichten_invertiert.svg")'/><div class="comment-text">{{comment.comment_text}}</div>
            </div>
        </div>
        <div class="textResult">{{getValue.text}}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ImageViewer from "@/components/ImageViewer.vue";

export default {
    data(){
        return{
            file:{},
            zoomed:0,
            lightBoxActive:false,
            refreshKey:1
        }
	},
    components:{
        //'backButton':Button,
        'imageViewer':ImageViewer
    },
    props:{
        field: Object,
        fix: Boolean,
        formId: Number,
        highlighted: Boolean,
        submissionId: String,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
    },
    computed:{
        hasSymbols: function(){
            if(this.field.field_uploads != undefined || this.comments.length > 0){
                return true
            }
            return false;
        },
        comments: function(){
            if(this.field.comments != undefined && this.field.comments.length >= 1){
                return this.field.comments
            }
            return [];
        },
        //why all this methods?!?!?! just get the values from field
        getValue: function() {
            var valueObj = {
                severity:2,
                text:"",
            };
            if(Array.isArray(this.field.field_value)){
                var result = this.field.field_value.filter(obj => {
                    return obj.selected === true;
                })
                //values can be true or false but we dont want that shown as text
                if(result[0] !== undefined){
                    if(typeof(result[0].value) === "string" && result[0].value !== "true" && result[0].value !== "false"){
                        valueObj.text = result[0].value;
                    }
                    var selected = result[0].option;
                    if(Array.isArray(this.field.options)){
                        if(Array.isArray(this.field.options) && this.field.options[selected] !== undefined){
                            valueObj.severity = parseInt(this.field.options[selected].severity);
                            //uncomment if we want lables shown in the docu instead of values
                            if(this.field.field_type?.type !== "boolean"){
                                valueObj.text = this.getValueForCurLanguage(this.field.options[selected].label).value;
                            }
                        }
                    }
                }
            }else if(typeof this.field.field_value === 'string' || this.field.field_value instanceof String){
                valueObj.text = this.field.field_value;
            }
            else if(typeof this.field.field_value === 'number' || this.field.field_value instanceof Number){
                valueObj.text = this.field.field_value;
            }
            else{
                if(this.field.field_value === true){
                    valueObj.severity = 0;
                }else{
                    valueObj.severity = 2;
                }
            }
            return valueObj;
        },
        getLabel: function() {
            return this.getValueForCurLanguage(this.field.field_label).value;
        },
        getPlaceholder: function() {
            return this.getValueForCurLanguage(this.field.field_placeholder).value;
        },
        getDescription: function() {
            return this.getValueForCurLanguage(this.field.field_description).value;
        },
        getRequired: function() {
            if(this.field.required){
                return this.field.required
            }else{
                return false
            }
        },
        ...mapGetters([
            'getValueForCurLanguage',
        ])
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
    .field-wrap{
        margin: 10px 10px;
        padding:10px 15px 10px 15px;
        background-color: $bgColorLightLight;
        border-radius: 3px;
        .result-wrap{
            display: flex;
            justify-content: space-between;
        }
        .textResult{
            width: 100%;
            text-align: right;
        }
    }
    .level0{
        
    }
    .level1{
        margin: 5px 0px;
    }
    .level2{
        margin: 0;
        .sepperator{
            border-bottom:1px solid rgba(0,0,0,.1);
            width: 100%;
            margin:auto auto 10px auto;
        }
    }
    .level2.field-wrap{
        padding:0px 15px 10px 15px;
    }
    .result{
        width:20px;
        text-align: right;
        align-self: flex-end;
    }
    .fieldSymbols{
        text-align: left;
        margin-top: 10px;
        height: auto;
        z-index: 10;
        .qrSymbol{
            width: 24px;
            height: 24px;
            padding: 2px;
        }
    }
    .comment{
        display: flex;
        align-items:center;
    }
    .comment-text{
        display:inline-block;
        margin-left: 10px;
    }
    .button-wrap{
        float:left;
        margin:20px;
        img{
            height:30px;
            width:30px;
        }
    }
</style>