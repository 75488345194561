<template>
    <div class="dopdown-full-wrap">
        <div class="select-wrap" v-on:click="showSelect()">
            <div class="select-button"></div>
            <div class="cur-value-wrap">
                <!-- auf string Bitte auswählen prüfen evtl nicht so schön -->
                <img v-if="getPropertyById(defaultValue, 'imgSrc') != ''" :src="getPropertyById(defaultValue, 'imgSrc')"/>
                <div v-else class="pleaseChoose">
                    {{$t("general.pleaseSelect")}}
                </div>
                <div class="cur-value">
                    <!--{{contents[defaultValue].value}}-->
                    {{getPropertyById(defaultValue,'value') !== "noTrailerSlug" ? getPropertyById(defaultValue,'value') : $t("tour.vehicle.noTrailer")}}
                </div>
                <div class="sepperator-wrap"><div class="sepperator"></div></div>
                <label>{{label}}</label>
            </div>
        <!-- maybe put dropdown-all-values-wrap in the app root and store data in store and activate via store method -->
        </div>
        <transition name="animate-slide-down">
            <div class="dropdown-all-values-wrap" v-if="isSelectOpen" v-on:click="hideSelect()">
                <div class="dropdown-title">{{dropdownText}}</div>
                <input type="text" :placeholder='$t("general.searchPlaceholder")' v-model='searchString' v-on:click.stop/>
                <ul>
                    <li v-for="content in filteredItems" :key="content.id" v-on:click="comitToStore(content.id)">
                        <!-- auf string "Bitte auswählen" prüfen evtl nicht so schön -->
                        <img v-if="getPropertyById(content.id, 'imgSrc') != ''" :src="getPropertyById(content.id, 'imgSrc')"/>
                        <span v-if='content.value !== "noTrailerSlug"'>{{content.value}}</span>
                        <span v-else>{{$t("tour.vehicle.noTrailer")}}</span>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'drop-down-fullscreen',
    props: {
        defaultValue: Number,
        label: String,
        pathToImgs: String,
        dropdownText: String,
        mutation: String,
        contents: {
            id: Number,
            value: String,
            imgSrc: String,
        }
    },
    data: function () {
        return {
            isSelectOpen: false,
            searchString: '',
        }
    },
    computed:{
        filteredItems() {
            return this.contents.filter(item => {
                return item.value.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1
            })
        }
    },
    methods: {
        showSelect: function () {
            this.isSelectOpen = true;
        },
        hideSelect: function () {
            this.isSelectOpen = false;
        },
        getImgUrl(img) {
            return require('@/assets'+img);
        },
        comitToStore(value){
            this.$store.commit(this.mutation,value)
        },
        getPropertyById(id,propertyName){
            if(propertyName== null || propertyName == undefined){
                propertyName = "value";
            }
            if(id == null || id == undefined || id < 0){
                return "";
            }else{
                var obj = this.contents.find((c) => {
                    return c.id === id;
                })
                if(obj){
                    return obj[propertyName];
                }else{
                    return "";
                }
            }
        },
    },
   
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.select-wrap{
    cursor:pointer;
}
.select-button{
    text-align: center;
    border-radius: 10px 10px 0 0;
    border:1px solid rgba(0,0,0,.4);
    height:20px;
    padding:3px;
    background: url('../../assets/drop_down.svg') no-repeat 50% 50%;
    background-origin: content-box;
    background-size: contain;
}
.cur-value-wrap{
    width: 100%;
    margin: 0 auto 10px auto;
    border-radius: 0 0 10px 10px;
    background-color: $bgColorLightLight;
    padding:5px;
    display:flex;
    flex-wrap: wrap;
    img{
        margin: auto;
        width: 200px;
    }
    .cur-value, .pleaseChoose{
        font-size: 1.2rem;
        min-width: 100%;
    }
    .sepperator-wrap{
        flex:0 0 100%;
        padding: 0 6%;
        .sepperator{
            border-bottom:1px solid rgba(0,0,0,.1);
            width: 100%;
            margin:auto;
        }
    }
    label{
        padding:5px;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
        text-align: center;
        width: 100%;
        cursor:pointer;
    }
}
.dropdown-all-values-wrap{
    background-color:$bgColorLightLight;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    min-height: 100%;
    padding:20px;
    .dropdown-title{
        margin:20px;
    }
    input{
        display: block;
        margin: auto;
        border: none;
        background: transparent;
        text-align: center;
        padding:5px;
        font-size: 1.2rem;
        min-width: 100%;
    }
    ul{
        list-style: none;
        margin:0;
        padding:0;
        li{
            display: flex;
            flex-direction: column;
            margin:10px;
            padding:10px;
            border-radius:5px;
            background-color:$bgColorLight;
            border:1px solid rgba(0,0,0,0.4);
            cursor:pointer;
        }
    }
    img{
        width: 200px;
        margin: auto;
    }
}
.animate-slide-down-enter{
  margin-top:-100vh;
}
.animate-slide-down-enter-active{
  transition: margin-top .5s;
}
.animate-slide-down-enter-to{
  margin-top:0;
}
.animate-slide-down-leave-active{
  margin-top:-100vh;
  transition: margin-top .5s;
}
</style>