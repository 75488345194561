<template>
    <div v-if='timeEntryList.length > 0' class="timeEntryList">
        <div class="tableHeader">
            <div class="tableHead one">{{$t('tour.time.time')}}</div>
            <div class="tableHead">{{$t('tour.time.type')}}</div>
            <div class="tableHead">{{$t('tour.time.destinationDescription')}}</div>
            <div v-if='isSupported("timeMileage")' class="tableHead">{{$t('tour.time.mileage')}}</div>
        </div>
        <div v-for='(entry,index) in timeEntryList' class="timeEntry" :key="index">
            <div class="tableCell one">{{entry.timer_start !== undefined ? convertTimestamp(entry.timer_start) : convertTimestamp(entry.startTime)}}</div>
            <div class="tableCell">{{entry.timer_type !== undefined ? $t("tour.time."+entry.timer_type) : $t("tour.time."+entry.type)}}</div>
            <div class="tableCell">{{entry.timer_description !== undefined ? entry.timer_description : entry.text}}</div>
            <div v-if='isSupported("timeMileage")' class="tableCell">{{entry.mileage !== undefined ? entry.mileage : entry.km}}</div>
        </div>
    </div>
</template>

<script>
import dateFunctions from '@/mixins/dateFunctions'
export default {
    props:{
        timers:Array
    },
    computed:{
        timeEntryList: function(){
            //return property timers if exist, else return current timers
            if(Array.isArray(this.timers) && this.timers.length > 0){
                return this.timers;
            }else{
                return this.$store.state.local.nachtragTimer.trackedTimesEntries;
            }
        },
    },
    methods:{
        isSupported(feature){
            return this.$store.getters.isFeatureSupportet(feature);
        },
    },
    mixins:[dateFunctions]
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";
.timeEntryList{
    width: 100%;
    border-radius: 10px;
    background-color: white;
    .tableHeader{
        margin:5px 0;
        padding:5px 0;
        display: flex;
        flex-direction: row;
    }
    .timeEntry{
        background-color:$bgColorLightLight;
        border-radius: 3px;
        margin:5px 0;
        padding:5px;
        display: flex;
        flex-direction: row;
    }
    .tableCell, .tableHead{
        box-sizing: border-box;
        flex-grow: 1;
        width: 100%;  // Default to full width
        padding: 5px 0;
        overflow: hidden; // Or flex might break
        list-style: none;
    }
    .one{
        width: 170px;
    }
}
</style>